import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import DownloadData from 'v-download'
import JsonCSV from 'vue-json-csv'
import {BootstrapVueIcons} from 'bootstrap-vue'
//import VueAnalytics from 'vue-analytics'



Vue.config.productionTip = false;
Vue.component('downloadCsv',JsonCSV)
Vue.use(DownloadData)
Vue.use(BootstrapVueIcons)



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Vue.filter('currency', (value) => {
  // if (typeof value !== "number") {
  //   return value;
  // }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });
  return formatter.format(value);
});


