<template>
<div>
  <div>
    <b-table striped hover
      :items="leaders"
      :fields="fields"
      :columns=4>

      <!-- <template #cell(profile_image)="data">
        <b-img :src= "data.item.profile_image" style="border-radius: 50%;"></b-img>
      </template> -->

       <template #cell(screen_name)="data" id="screen_name">
        <b-link :href="'https://www.twitter.com/' + data.item.screen_name">
        <b-img :src= "data.item.profile_image" style="border-radius: 50%;"></b-img>
        @{{ data.item.screen_name }}
        </b-link>

      </template>

      <template #cell(avg_performance)="data">
        <b class="text-info">{{ Number(data.item.avg_performance).toFixed(2) }}%</b>
      </template>

      <template #cell(followers)="data">
        <b>{{ data.item.followers.toLocaleString() }}</b>
      </template>
      </b-table>

  </div>
</div>
</template>

<script>
export default {
  props:{'leaders': Array},
  name:'AccountListing',
  data() {
    return{
      fields:[

              {
                key:'screen_name',
                label:'Twitter Name',
                tdClass: 'text-left',
                thClass: 'text-left'
              },
              {
                key:'avg_performance',
                label:'Performance (7 day)',
                sortable:true
              },
              {
                key:'symbols',
                label:'Symbols Mentioned',
                sortable:true
              },
              {
                key:'followers',
                label:'Followers',
                sortable:true
              }]
    }
  }

}
</script>

<style>
#screen_name {
  align-content: "left";
}
</style>
