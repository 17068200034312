<template>
<div>
  <template>
<download-csv
  class="text-right"
  :data="symbols"
  name = "trending_symbols.csv">
  Download
  <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
  </download-csv>


</template>
  <div>
    <b-table striped hover
      :items="symbols"
      :fields="fields"
      stacked="md"
      responsive

    >

        <template #cell(ranking)="data" id="ranking">
               <b>{{ data.item.ranking }}</b>
       </template>

       <template #cell(symbol)="data" id="symbol">
               {{ data.item.symbol }}
       </template>

        <template #cell(name)="data" id="name">
               {{ data.item.name }}
       </template>

      <template #cell(last_price)="data" id="last_price">
              {{ data.item.last_price | currency}}
      </template>

      <template #cell(overall_sentiment)="data">
              {{ data.item.overall_sentiment }}
      </template>

       <template #cell(sector)="data" id="sector">
               {{ data.item.sector }}
       </template>
      </b-table>

  </div>
</div>
</template>

<script>
export default {
  props:{'symbols': Array},
  name:'Trending',
  data() {
    return{
      fields:[
              {
                key:'ranking',
                label:'Rank',
                tdClass: 'text-left',
                thClass: 'text-left',
                sortable:true
              },
              {
                key:'symbol',
                label:'Symbol',
                tdClass: 'text-left',
                thClass: 'text-left',
                sortable:true
              },
              {
                key:'name',
                label:'Name',
                tdClass: 'text-left',
                thClass: 'text-left'
              },
              {
                key:'last_price',
                label:'Last Price',
                thClass:'text-left',
                tdClass:'text-left',
                sortable:false
              },
              {
                key:'overall_sentiment',
                label:'Sentiment',
                thClass:'text-left',
                tdClass:'text-left',
                sortable:true
              },
              {
                key:'sector',
                label:'Sector',
                tdClass: 'text-left',
                thClass: 'text-left',
                sortable:true
              }]
    }
  },
  mounted(){


  }

}
</script>

<style>
#screen_name {
  align-content: "left";
}
</style>
