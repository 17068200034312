<template>
  <div class="LeaderBoard">
    <b-container>
    <account-listing :leaders=leaders></account-listing>
    </b-container>
  </div>
</template>

<script>
import AccountListing from '@/components/AccountListing'

export default{
  name:'LeaderBoard',
  components:{
    "account-listing":AccountListing
  },
  data(){
    return {
      leaders:[]
    }

  },
  methods: {
    async getLeaders(){
       const res = await fetch("leader_board.json", {
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
        })


       const val = await res.json();
       this.leaders = val;
       console.log(this.leaders)
      //  this.displayStatuses = val.slice(0,9);
      //  this.rows = this.statuses.length;

  },
   getLeaderBoard(){
    fetch("https://ws6j127rga.execute-api.us-east-1.amazonaws.com/prod/leaderboard/",{
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.leaders.push(...data);
      })
      .catch((error) => {
        console.log(error);
      })
  }
  },
  mounted(){
    this.getLeaderBoard();
    console.log('LeaderBoard: '  + this.leaders)
  }
}
</script>
