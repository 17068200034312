<template>
  <div class="TrendingSymbols">
 <b-container fluid>
      <b-row>
        <b-col cols="12" md="4" sm="12" lg="2" class="border-right border-dark">
          <div id = "filters">
            <span><b>Last Updated:</b> {{last_updated | formatDate}} </span>
            <!-- <h4>Filters</h4> -->
          </div>
          <b-form-group description= 'Trending Time Frame' class = "mt-3">
            <b-form-select id="timeSelect" v-model="filters.selected_time_frame" :options = "filters.time_frame_options" size="sm" class="mt-3"></b-form-select>
          </b-form-group>

           <b-form-group description= 'Exchange' class = "mt-3">
            <b-form-select id="exchange" v-model="filters.selected_exchange" :options = "filters.exchange_options" size="sm" class="mt-3"></b-form-select>
          </b-form-group>

          <b-form-group description= 'Sector' class = "mt-3">
            <b-form-select id="sector" v-model="filters.selected_sector" :options = "filters.sector_options" size="sm" class="mt-3"></b-form-select>
          </b-form-group>

           <b-form-group description= 'Sentiment' class = "mt-3">
            <b-form-select id="sentiment" v-model="filters.selected_sentiment" :options = "filters.sentiment_options" size="sm" class="mt-3"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="8" sm="12" lg="10">
          <trending-symbols :symbols=filteredResults></trending-symbols>
        </b-col>

      </b-row>
      <b-row>

      </b-row>

    </b-container>



  </div>
</template>

<script>
import Trending from '@/components/Trending'
import moment from 'moment'
import {Bar} from 'vue-chartjs'

export default{
  name:'TrendingSymbols',
  components:{
    "trending-symbols":Trending
  },
  filters:{
    formatDate: function(value){
      return moment.utc(value).local().format('MM/DD/YYYY hh:mm a');
    }
  },
  extends:Bar,
  data(){
    return {
      filters:{
        selected_time_frame:"0",
        selected_exchange:"all",
        selected_sentiment:"all",
        selected_sector:"all",
        time_frame_options:[
        {value:'0', text:'Today'},
        {value:'1_Hour', text:'1 Hour'},
        {value:'3_Hour', text:'3 Hours'}
      ],
        exchange_options:[
          {value:'all', text:'All'},
          {value:'amex', text:'Amex'},
          {value:'nyse', text:'NYSE'},
          {value:'nasdaq', text:'Nasdaq'}
          // {value:'na', text:'Unclassified'}
        ],
        sentiment_options:[
          {value:'all', text:'All'},
          {value:'postive', text:'Positive'},
          {value:'neutral', text:'Neutral'},
          {value:'negative', text:'Negative'}

        ],
        sector_options:[
          {value:'all', text:'All'},
          {value:'Basic Industries', text:'Basic Industries'},
          {value:'Capital Goods', text:'Capital Goods'},
          {value:'Consumer Durables', text:'Consumer Durables'},
          {value:'Consumer Non-Durables', text:'Consumer Non-Durables'},
          {value:'Consumer Services', text:'Consumer Services'},
          {value:'Energy', text:'Energy'},
          {value:'Finance', text:'Finance'},
          {value:'Health Care', text:'Health Care'},
          {value:'Miscellaneous', text:'Miscellaneous'},
          {value:'Public Utilities', text:'Public Utilities'},
          {value:'Technology', text:'Technology'},
          {value:'NA',text:'Unclassified'}

        ]
      }
      ,
      symbols:[],
      last_updated:[],
      chartData:[],
      chartOptions:[]


    }

  },
  methods: {
    getTrendingSymbols(){
    fetch("https://ws6j127rga.execute-api.us-east-1.amazonaws.com/prod/trending/",{
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.symbols.push(...data);

      })
      .catch((error) => {
        console.log(error);
      })


    },
    getLastUpdated(){
      fetch("https://ws6j127rga.execute-api.us-east-1.amazonaws.com/prod/lastupdate/",{
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.last_updated = data[0]['insrt_dttm']
        // this.last_updated.push(...data);
        console.log(data[0]['insrt_dttm'])

      })
      .catch((error) => {
        console.log(error);
      })

    }
  },
  computed: {
    filteredResults()
    {
      if (this.filters.selected_exchange != 'all' || this.filters.selected_sentiment != 'all' || this.filters.selected_sector != 'all'){
        let fltr_time_frame = this.filters.selected_time_frame
        let fltr_exchange = (this.filters.selected_exchange == 'all')? '':this.filters.selected_exchange
        let fltr_sentiment = (this.filters.selected_sentiment == 'all')? '':this.filters.selected_sentiment
        let fltr_sector = (this.filters.selected_sector == 'all')? '':this.filters.selected_sector
        console.log(fltr_exchange)
        return this.symbols.filter(trending => trending.time_frame.includes(fltr_time_frame) && trending.exchange.includes(fltr_exchange) && trending.overall_sentiment.includes(fltr_sentiment) && trending.sector.includes(fltr_sector))
      }
        return this.symbols.filter(trending => trending.time_frame.includes(this.filters.selected_time_frame))
    }

  }

  ,
  mounted(){
    this.getTrendingSymbols();
    this.getLastUpdated();
    //  this.renderChart(data, options)

  }
}
</script>

<style>
#filters {
  padding-top: 10px;
}
</style>
