<template>
  <div class="container">
    <bar-chart
      v-if= "loaded"
      :chartdata= "chartdata"
      :options= "options"
      />
  </div>
</template>

<script>
import BarChart from '@/components/TrendingChart.vue'

export default {
  name: 'BarChartContainer',
  components: { BarChart },
  data(){
    return{
    filteredSymbols:[],
    symbols:[],
    loaded: false,
    chartdata: {
      labels:[],
      datasets:[{
        label:'Twitter Mentions',
        data:[]
      }
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false
    }
    }
  },
  methods: {
    getTrendingSymbols(){
      fetch("https://ws6j127rga.execute-api.us-east-1.amazonaws.com/prod/trending/",{
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.symbols.push(...data)

        this.filteredSymbols = this.symbols.filter(trend => trend.time_frame == '0');

        this.chartdata.labels = this.filteredSymbols.map(a => a.symbol)
        this.chartdata.datasets[0].data = this.filteredSymbols.map(b => b.tweets)
        this.sortData()
        this.loaded = true


      })
      .catch((error) => {
        console.log(error);
      })



    },
    sortData(){
     let merged = this.chartdata.labels.map((symbol, i) => {
       return {"symbol": this.chartdata.labels[i], "tweets": this.chartdata.datasets[0].data[i]}
     });

       const dataSort = merged.sort(function (a,b) {
       return b.tweets - a.tweets
     });
    //  console.log('Sorted: ', dataSort)
    const label = [];
    const datapoint = [];

    for (var i = 0; i < 25; i++){
      label.push(dataSort[i].symbol)
      datapoint.push(dataSort[i].tweets)
    }

    this.chartdata.labels = label
    this.chartdata.datasets[0].data = datapoint
    }

  },
    computed: {
    filteredResults()
    {
      if (this.filters.selected_exchange != 'all' || this.filters.selected_sentiment != 'all' || this.filters.selected_sector != 'all'){
        let fltr_time_frame = this.filters.selected_time_frame
        let fltr_exchange = (this.filters.selected_exchange == 'all')? '':this.filters.selected_exchange
        let fltr_sentiment = (this.filters.selected_sentiment == 'all')? '':this.filters.selected_sentiment
        let fltr_sector = (this.filters.selected_sector == 'all')? '':this.filters.selected_sector
        console.log(fltr_exchange)
        return this.symbols.filter(trending => trending.time_frame.includes(fltr_time_frame) && trending.exchange.includes(fltr_exchange) && trending.overall_sentiment.includes(fltr_sentiment) && trending.sector.includes(fltr_sector))
      }
        return this.symbols.filter(trending => trending.time_frame.includes(this.filters.selected_time_frame))
    }

  },
  async mounted () {
    this.loaded = false
    try {
      this.getTrendingSymbols();
      // this.loaded = true
    } catch (e) {
      console.error(e)
    }
  }
}
</script>
