<template>
    <div id="app">
      <nav-top></nav-top>
      <router-view/>
    </div>
</template>


<script>
import TopNav from '@/components/TopNav'

export default{
  components:{'nav-top':TopNav}
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f3f3f3;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: rgba(134, 134, 134, 0.5)
}

#nav a.router-link-exact-active {
  color: #fff}


small {
    /* background: url(../images/alpha.png);*/
    background: #f3f3f3;
    border: none;
    font-size: 15px;
    padding: 0 5px;
}

body{
   background-color: #f3f3f3;
}

</style>
